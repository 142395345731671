import './Blogs.css';
import { useState } from 'react';
import NavigationBar from '../navbar/NavigationBar';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import BlogsCount from './BlogsCount';

function Blogs() {
    const [ibmmqCnt, setIBMMQCnt] = useState(0);
    const [kafkaCnt, setKafkaCnt] = useState(0);
    const [supplyChainCnt, setSupplyChainCnt] = useState(0);
    const [devSecOpsCnt, setDevSecOpsCnt] = useState(0);
    const [frontendCnt, setFrontEndCnt] = useState(0);
    const [cloudCnt, setCloudCnt] = useState(0);
    const [javaCnt, setJavaCnt] = useState(0);
    const [aiCnt, setAICnt] = useState(0);

    const blogCount = (category) => {
        fetch(process.env.REACT_APP_CONTENT_COUNT_API_URL + `?name=${category}`)
            .then(response => {
                if (category === 'ibmmq') {
                    response.text().then(value => {
                        let parsed = JSON.parse(value);
                        if (parsed[0] && parsed[0].$1) {
                            setIBMMQCnt(parsed[0].$1);
                        }
                    });
                } else if (category === 'kafka') {
                    response.text().then(value => {
                        let parsed = JSON.parse(value);
                        if (parsed[0] && parsed[0].$1) {
                            setKafkaCnt(parsed[0].$1);
                        }
                    });
                } else if (category === 'supplychain') {
                    response.text().then(value => {
                        let parsed = JSON.parse(value);
                        if (parsed[0] && parsed[0].$1) {
                            setSupplyChainCnt(parsed[0].$1);
                        }
                    });
                } else if (category === 'devsecops') {
                    response.text().then(value => {
                        let parsed = JSON.parse(value);
                        if (parsed[0] && parsed[0].$1) {
                            setDevSecOpsCnt(parsed[0].$1);
                        }
                    });
                } else if (category === 'frontend') {
                    response.text().then(value => {
                        let parsed = JSON.parse(value);
                        if (parsed[0] && parsed[0].$1) {
                            setFrontEndCnt(parsed[0].$1);
                        }
                    });
                } else if (category === 'cloud') {
                    response.text().then(value => {
                        let parsed = JSON.parse(value);
                        if (parsed[0] && parsed[0].$1) {
                            setCloudCnt(parsed[0].$1);
                        }
                    });
                } else if (category === 'java') {
                    response.text().then(value => {
                        let parsed = JSON.parse(value);
                        if (parsed[0] && parsed[0].$1) {
                            setJavaCnt(parsed[0].$1);
                        }
                    });
                } else if (category === 'ai') {
                    response.text().then(value => {
                        let parsed = JSON.parse(value);
                        if (parsed[0] && parsed[0].$1) {
                            setAICnt(parsed[0].$1);
                        }
                    });
                }
            })
            .catch(error => {
                console.log(error);
            });
    }

    return (
        <>
            <NavigationBar></NavigationBar>
            <div className="blogs-bg">
                <Container style={{ marginTop: "1rem", marginBottom: "1rem" }}>
                    <Row xs={1} sm={2} md={3} lg={4} className="g-4">
                        <Col>
                            <Nav.Link href="blogs/ibmmq">
                                <Card onLoad={blogCount('ibmmq')}>
                                    <Card.Img variant="top" src="./assets/images/blogs/ibm-mq-hero.jpeg" />
                                    <Card.Body>
                                        <Card.Title>IBM MQ</Card.Title>
                                        <Card.Text>A message-oriented middleware product that IBM launched in December 1993. It was originally called MQSeries, and was renamed WebSphere MQ in 2002 to join the suite of WebSphere products. In April 2014, it was renamed IBM MQ.</Card.Text>
                                    </Card.Body>
                                    <BlogsCount count={ibmmqCnt}></BlogsCount>
                                </Card>
                            </Nav.Link>
                        </Col>
                        <Col>
                            <Nav.Link href="blogs/kafka">
                                <Card onLoad={blogCount('kafka')}>
                                    <Card.Img variant="top" src="./assets/images/blogs/kafka.png" />
                                    <Card.Body>
                                        <Card.Title>Kafka</Card.Title>
                                        <Card.Text>An open-source system developed by the Apache Software Foundation written in Java and Scala, used to provide a unified, high-throughput, low-latency platform for handling real-time data feeds.</Card.Text>
                                    </Card.Body>
                                    <BlogsCount count={kafkaCnt}></BlogsCount>
                                </Card>
                            </Nav.Link>
                        </Col>
                        <Col>
                            <Nav.Link href="blogs/supplychain">
                                <Card onLoad={blogCount('supplychain')}>
                                    <Card.Img variant="top" src="./assets/images/blogs/supply-chain.jpg" />
                                    <Card.Body>
                                        <Card.Title>Supply Chain</Card.Title>
                                        <Card.Text>A network of organizations, people, activities, information, and resources involved in handling or distributing and moving a product or service from the supplier to the customer.</Card.Text>
                                    </Card.Body>
                                    <BlogsCount count={supplyChainCnt}></BlogsCount>
                                </Card>
                            </Nav.Link>
                        </Col>
                        <Col>
                            <Nav.Link href="blogs/devsecops">
                                <Card onLoad={blogCount('devsecops')}>
                                    <Card.Img variant="top" src="./assets/images/blogs/devsecops.png" />
                                    <Card.Body>
                                        <Card.Title>DevSecOps</Card.Title>
                                        <Card.Text>The practice of integrating security testing at every stage of the software development process. It includes tools and processes that encourage collaboration between developers, security specialists, and operation teams to build software that is both efficient and secure.</Card.Text>
                                    </Card.Body>
                                    <BlogsCount count={devSecOpsCnt}></BlogsCount>
                                </Card>
                            </Nav.Link>
                        </Col>
                        <Col>
                            <Nav.Link href="blogs/frontend">
                                <Card onLoad={blogCount('frontend')}>
                                    <Card.Img variant="top" src="./assets/images/blogs/frontend.png" />
                                    <Card.Body>
                                        <Card.Title>Front-End</Card.Title>
                                        <Card.Text>The development of the graphical user interface of a website, through the use of HTML, CSS, and JavaScript, so that users can view and interact with that website.</Card.Text>
                                    </Card.Body>
                                    <BlogsCount count={frontendCnt}></BlogsCount>
                                </Card>
                            </Nav.Link>
                        </Col>
                        <Col>
                            <Nav.Link href="blogs/cloud">
                                <Card onLoad={blogCount('cloud')}>
                                    <Card.Img variant="top" src="./assets/images/blogs/cloud.png" />
                                    <Card.Body>
                                        <Card.Title>Cloud Computing</Card.Title>
                                        <Card.Text>The on-demand availability of computer system resources, especially data storage and computing power, without direct active management by the user.</Card.Text>
                                    </Card.Body>
                                    <BlogsCount count={cloudCnt}></BlogsCount>
                                </Card>
                            </Nav.Link>
                        </Col>
                        <Col>
                            <Nav.Link href="blogs/java">
                                <Card onLoad={blogCount('java')}>
                                    <Card.Img variant="top" src="./assets/images/blogs/java.jpg" />
                                    <Card.Body>
                                        <Card.Title>Java</Card.Title>
                                        <Card.Text>Java is a high-level, class-based, object-oriented programming language that is designed to have as few implementation dependencies as possible.</Card.Text>
                                    </Card.Body>
                                    <BlogsCount count={javaCnt}></BlogsCount>
                                </Card>
                            </Nav.Link>
                        </Col>
                        <Col>
                            <Nav.Link href="blogs/ai">
                                <Card onLoad={blogCount('ai')}>
                                    <Card.Img variant="top" src="./assets/images/blogs/ai.jpg" />
                                    <Card.Body>
                                        <Card.Title>AI</Card.Title>
                                        <Card.Text>Artificial Intelligence</Card.Text>
                                    </Card.Body>
                                    <BlogsCount count={aiCnt}></BlogsCount>
                                </Card>
                            </Nav.Link>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
}


export default Blogs;