import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Spinner from 'react-bootstrap/Spinner';
import { FormTextTips } from "./FormTextTips.js";

/**
 * Displays the content form in edit mode.
 *
 * @callback props.handleSubmit is callback function to handle submit
 * @callback props.handleShow is callback function to display preview modal
 * @param {React.MutableRefObject} props.formContentRef is ref callback function to reference the content form
 * @param {string} props.aboutContent contains the content to be edited
 * @param {boolean} props.apiWaiting indicates whether api is in-progress
 * @return {div} html tags to render
 */
function ContentEdit(props) {
    return (
        <div className="form-box">
            <Form onSubmit={props.handleSubmit}>
                <Form.Group className="mb-3" controlId="formBasicContent">
                    <Row>
                        <Form.Label>Content</Form.Label>
                    </Row>
                    <Row>
                        <FormTextTips></FormTextTips>
                    </Row>
                    <Row>
                        <Form.Control
                            required
                            ref={props.formContentRef}
                            defaultValue={props.aboutContent}
                            disabled={props.apiWaiting}
                            name="formContent"
                            as="textarea" rows={10} />
                    </Row>
                </Form.Group>
                {
                    !props.apiWaiting &&
                    <Button variant="success" type="button" onClick={props.handleShow} disabled={props.apiWaiting}>
                        Preview
                    </Button>
                }
                {
                    props.apiWaiting &&
                    <Button variant="success" type="button" onClick={props.handleShow} disabled>
                        Preview
                    </Button>
                }
                {' '}
                {
                    !props.apiWaiting &&
                    <Button variant="primary" type="submit">
                        Submit
                    </Button>
                }
                {
                    props.apiWaiting &&
                    <Button variant="primary" disabled>
                        <Spinner
                            as="span"
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                        />
                        Submitting...
                    </Button>
                }
            </Form>
        </div>
    );
}

export { ContentEdit };