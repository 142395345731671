import './App.css';
import NavigationBar from './navbar/NavigationBar';
import Card from 'react-bootstrap/Card';
import { useState } from 'react';
import Container from 'react-bootstrap/Container';

const NUM_IMAGES = 8;
const NUM_QUOTES = 8;

function App() {
  let currIdx = Math.floor(Math.random() * (NUM_IMAGES));
  const [idxCounter, setIdxCounter] = useState(currIdx);

  const bgURLs = [
    { idx: 0, url: `url('./assets/images/bg/hawaii.jpg')` },
    { idx: 1, url: `url('./assets/images/bg/dolphins.jpg')` },
    { idx: 2, url: `url('./assets/images/bg/clouds.jpg')` },
    { idx: 3, url: `url('./assets/images/bg/wheat.jpg')` },
    { idx: 4, url: `url('./assets/images/bg/cloud-sea.jpg')` },
    { idx: 5, url: `url('./assets/images/bg/mountain-side.jpg')` },
    { idx: 6, url: `url('./assets/images/bg/sea-waves.jpg')` },
    { idx: 7, url: `url('./assets/images/bg/rock-flowers.jpg')` }
  ];

  const quotes = [
    { idx: 0, author: 'Og Mandino', text: 'Always do your best. What you plant now, you will harvest later.' },
    { idx: 1, author: 'Marcus Tullius Cicero', text: 'More is lost by indecision than wrong decision.' },
    { idx: 2, author: 'Vince Lombardi', text: 'Perfection is not attainable. But if we chase perfection we can catch excellence.' },
    { idx: 3, author: 'Warren Buffet', text: `Someone's sitting in the shade today because someone planted a tree a long time ago.` },
    { idx: 4, author: 'Shakespeare', text: 'When the sea was calm, all ships alike showed mastership in floating.' },
    { idx: 5, author: 'Neale Donald Walsch', text: 'Life begins at the end of your comfort zone.' },
    { idx: 6, author: 'H. Jackson Brown, Jr.', text: 'The best preparation for tomorrow is doing your best today.' },
    { idx: 7, author: 'Francis of Assisi', text: `Start by doing what's necessary; then do what's possible; and suddenly you are doing the impossible.` },
  ];

  const initialState = bgURLs.find(obj => obj.idx === currIdx);
  const [backgroundUrl, setBackgroundUrl] = useState(initialState.url);

  const initialQuote = quotes.find(obj => obj.idx === currIdx);
  const [quote, setQuote] = useState(initialQuote);

  const myStyle = {
    backgroundImage: `${backgroundUrl}`,
    height: '100vh',
    // marginTop: '-70px',
    // fontSize: '50px',
    backgroundSize: 'cover',
    backgroundRepeat: 'repeat',
    overflow: 'hidden' // due to the block quote added in the center of the screen
  };

  const handleMouseClick = event => {
    const newIdxCounter = idxCounter + 1;
    setIdxCounter(newIdxCounter);

    const newImageURL = bgURLs.find(obj => obj.idx === newIdxCounter % NUM_IMAGES).url;
    setBackgroundUrl(newImageURL);

    const newQuote = quotes.find(obj => obj.idx === newIdxCounter % NUM_QUOTES);
    setQuote(newQuote);

    // event.stopPropogation() // not a function
    event.preventDefault()
  };

  return (
    <div style={myStyle}>
      <div>
        <NavigationBar bgFn={handleMouseClick}></NavigationBar>
      </div>
      <Container>
        <div className="centered" style={{ opacity: 0.7 }}>
          <Card>
            <Card.Header>Quote</Card.Header>
            <Card.Body>
              <blockquote className="blockquote mb-0">
                <p>
                  {' '}{quote.text}{' '}
                </p>
                <footer className="blockquote-footer">
                  {quote.author}
                </footer>
              </blockquote>
            </Card.Body>
          </Card>
        </div>
      </Container>
    </div>
  );
}

export default App;