import './NavigationBar.css';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Modal from 'react-bootstrap/Modal';
import { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import { getUserInfo } from "./../shared/UserUtility.js";

function NavigationBar({ bgFn }) {
    const redirect = window.location.pathname;
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleLogout = (event) => {
        event.preventDefault();

        const requestOptions = {
            mode: 'no-cors'
        };

        fetch('/.auth/logout?post_logout_redirect_uri=' + redirect, requestOptions)
            .then((_) => {
                handleClose();
                window.location.reload(false);
            });
    }

    const [userInfo, setUserInfo] = useState([]);

    useEffect(() => {
        getUserInfo().then((loggedUserInfo) => {
            setUserInfo(loggedUserInfo);
            if (loggedUserInfo) {
                let currUserAPI = sessionStorage.getItem('dXNlcl9sb2dnZWQ=');
                if (!currUserAPI) {
                    fetch(process.env.REACT_APP_IP_API_URL).then(response => {
                        if (response.ok) {
                            response.text().then(value => {
                                loggedUserInfo.currIp = value;

                                const requestOptions = {
                                    method: 'POST',
                                    headers: {
                                        'Content-Type': 'application/json'
                                    },
                                    body: JSON.stringify(loggedUserInfo)
                                };

                                fetch(process.env.REACT_APP_USER_API_URL, requestOptions)
                                    .then(response => {
                                        if (response.ok) {
                                            sessionStorage.setItem('dXNlcl9sb2dnZWQ=', 'success');
                                        }
                                    })
                                    .catch(error => {
                                        console.log(error);
                                    });
                            });
                        } else {
                            console.log('Navi ipLoc status: ' + response.status);
                        }
                    });
                }
            } else {
                // logout
                sessionStorage.removeItem('dXNlcl9sb2dnZWQ=');
            }
        }).catch(() => {
            console.log('Authentication currently disabled. Go to Contact, and feel free to send me an inquiry.')
        });
    }, []);

    const [currWidth, setCurrWidth] = useState(window.innerWidth);
    function handleNavMenuResize() {
        setCurrWidth(window.innerWidth);
    }
    window.addEventListener('resize', handleNavMenuResize);

    function enlargePicture() {
        window.open('/assets/logo/jk_logo_nature.jpeg', 'targetWindow', 'toolbar=no, location=no, status=no, menubar=no, scrollbars=yes, resizable=yes, width=1090px, height=550px, top=25px left=120px');
        return false;
    }

    return (
        <>
            <Navbar bg="dark" expand="lg">
                <Container>
                    {/* <Navbar.Brand href="/" style={{ cursor: "default" }}> */}
                    <Navbar.Brand style={{ cursor: "default" }}>
                        <img width="40"
                            alt="Home Logo"
                            src="/assets/logo/jk_logo_nature.jpeg"
                            onClick={enlargePicture} />
                        {
                            userInfo
                            &&
                            <span className="ms-3" style={{ color: 'white' }}>Welcome {userInfo.userDetails}!</span>
                        }
                        {
                            !userInfo
                            &&
                            <span className="ms-3" onClick={bgFn} style={{ color: 'white' }}>Home</span>
                        }
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" style={{ background: 'white' }}>
                        {/* <i class="icon-collapse"></i> */}
                    </Navbar.Toggle>
                    <Navbar.Collapse className="justify-content-end flex-grow-1 pe-3" >
                        <Nav>
                            <Nav.Link className={currWidth < 275 ? '' : 'ms-auto'} style={{ color: 'white' }} href="/projects">Projects</Nav.Link>
                            {/* <Nav.Link className={currWidth < 275 ? '' : 'ms-auto'} style={{ color: 'white' }} href="/timeline">Timeline</Nav.Link> */}
                            <NavDropdown className={currWidth < 275 ? '' : 'ms-auto'} style={{ textAlign: `${currWidth < 275 ? 'left' : 'right'}` }} title="Blogs" id="basic-nav-dropdown">
                                <NavDropdown.Item style={{ textAlign: 'center' }} href="/blogs">View</NavDropdown.Item>
                                {
                                    userInfo
                                    &&
                                    <NavDropdown.Divider />
                                }
                                {
                                    userInfo
                                    &&
                                    <NavDropdown.Item style={{ textAlign: 'center' }} href="/blogs/new">
                                        Create
                                    </NavDropdown.Item>
                                }
                            </NavDropdown>
                            <Nav.Link className={currWidth < 275 ? '' : 'ms-auto'} style={{ color: 'white' }} href="/contact">Contact</Nav.Link>
                            <Nav.Link className={currWidth < 275 ? '' : 'ms-auto'} style={{ color: 'white' }} href="/about">About</Nav.Link>
                            <Nav.Link className={currWidth < 275 ? '' : 'ms-auto'} style={{ color: 'white' }} onClick={handleShow}>
                                <img alt="Login Logo" src="/assets/icons/enter.png" />
                            </Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
            {
                !userInfo
                &&
                <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Login</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <img alt="Github Logo" src="/assets/icons/github.png" />
                        {' '}
                        <a href={`/.auth/login/github?post_login_redirect_uri=${redirect}`}>Via Github</a>
                    </Modal.Body>
                    <Modal.Body>
                        <img alt="Github Logo" src="/assets/icons/hierarchy.png" />
                        {' '}
                        <a href={`/.auth/login/aad?post_login_redirect_uri=${redirect}`}>Via Azure AD</a>
                    </Modal.Body>
                    <Modal.Body>
                        <img alt="Github Logo" src="/assets/icons/twitter.png" />
                        {' '}
                        <a href={`/.auth/login/twitter?post_login_redirect_uri=${redirect}`}>Via Twitter</a>
                    </Modal.Body>
                </Modal>
            }
            {
                userInfo
                &&
                <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Logout</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        Are you sure?
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-secondary" onClick={handleClose}>
                            No
                        </Button>
                        <Button variant="outline-primary" onClick={handleLogout}>
                            Yes
                        </Button>
                    </Modal.Footer>
                </Modal>
            }
        </>
    );
}

export default NavigationBar;