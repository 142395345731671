import { ReactMarkdownWithPlugins } from "./ReactMarkdownWithPlugins";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

function Preview(props) {
    return (
        <>
            <style>
                {
                    `table {
                        display: block !important; 
                        overflow: auto !important;
                    }

                    div.modal-body p {
                        margin-top: 1rem !important;
                    }
                        
                    div.math.math-display {
                        overflow-x: auto !important;
                    }`
                }
            </style>
            <Modal show={props.preview.show} onHide={props.handleCallBack}>
                <Modal.Header closeButton>
                    <Modal.Title>Preview</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ReactMarkdownWithPlugins content={props.preview.content}></ReactMarkdownWithPlugins>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={props.handleCallBack}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export { Preview };