function BlogsCount(props) {
    let count;
    if (props && props.count) {
        count = props.count;
    } else {
        count = 0;
    }

    return (
        <div className="text-right position-absolute top-0 end-0">
            {
                count > 0 && count < 10 &&
                <div className="blog-card-circle">
                    {count}
                </div>
            }
            {
                count >= 10 && count < 100 &&
                <div className="blog-card-circle" style={{ width: 40, height: 40 }}>
                    {count}
                </div>
            }
            {
                count >= 100 &&
                <div className="blog-card-circle" style={{ width: 45, height: 45 }}>
                    {100 + '+'}
                </div>
            }
        </div>
    )
}

export default BlogsCount;