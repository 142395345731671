import './Projects.css';
import NavigationBar from "../navbar/NavigationBar";
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
// import ListGroup from 'react-bootstrap/ListGroup';

function Projects() {

    return (
        <>
            <NavigationBar></NavigationBar>
            <div className="projects-bg">
                <Container style={{ marginTop: "1rem", marginBottom: "1rem" }}>
                    <Row xs={1} md={1} lg={1} className="g-4">
                        <Col>
                            <Nav.Link href="projects/openai">
                                <div className="center">
                                    <Card className="text-center" style={{ width: '18rem' }}>
                                        <Card.Img variant="top" src="./assets/images/projects/ChatGPT.jpg" />
                                        <Card.Body>
                                            <Card.Text>Integration with most popular project of the century - ChatGPT</Card.Text>
                                        </Card.Body>
                                    </Card>
                                </div>
                            </Nav.Link>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
}

export default Projects;