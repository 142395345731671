import Form from 'react-bootstrap/Form';

function FormTextTips() {
    return (
        <Form.Text className="text-muted" style={{ marginBottom: ".5rem" }}>
            {' '}
            <span>
                Tips:{' '}
            </span>
            <a href="https://github.com/remarkjs/remark-gfm#use"
                rel="noreferrer"
                target="_blank">
                gfm
            </a>
            {' '}
            <a href="https://www.tablesgenerator.com/markdown_tables"
                rel="noreferrer"
                target="_blank">
                md-table-gen
            </a>
            {' '}
            <a href="https://katex.org/docs/supported.html"
                rel="noreferrer"
                target="_blank">
                math
            </a>
            {' '}
            <a href="https://gist.github.com/rxaviers/7360908"
                rel="noreferrer"
                target="_blank">
                emojis
            </a>
            {' '}
            <a href="https://github-emoji-picker.rickstaa.dev/"
                rel="noreferrer"
                target="_blank">
                emojis-picker
            </a>
        </Form.Text>
    );
}

export { FormTextTips };